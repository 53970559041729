import L from 'leaflet';
import { labelIcon } from './LabelIcon';

export var LabelMarker = L.Marker.extend({
  options: {
    properties: {},
    labelingInfo: {},
    offset: [0, 0]
  },

  initialize: function (latlng, options) {
    L.setOptions(this, options);
    this._latlng = L.latLng(latlng);

    var symbol = options.labelingInfo[0].symbol;
    console.log(symbol);
    this.options.color = this._createColor(symbol.color);
    this.options.font = symbol.font;
    this.options.font.size = symbol.font.size;
    this.options.haloColor = this._createColor(symbol.haloColor);
  
    var labelText = this._createLabelText(this.options.properties, this.options.labelingInfo);
    this._setLabelIcon(labelText, this.options);
  }, 

  _createColor: function (clr) {   
    if (!clr) {
      return;
    }
    var color = 'rgb({0},{1},{2},{3})';
    color = color.replace('{0}', clr[0]);
    color = color.replace('{1}', clr[1]);
    color = color.replace('{2}', clr[2]);
    color = color.replace('{3}', Math.round(clr[3] / 255));
    return color;
  },

  _createLabelText: function (properties, labelingInfo) {
    var r = /\[([^\]]*)\]/g;
    var labelText = labelingInfo[0].labelExpression;
    if(!labelText){
      labelText = labelingInfo[0].labelExpressionInfo.expression;
    }

    labelText = labelText.replace('$feature', '').replace(r, function (s) {
      var m = r.exec(s);
      var attr = m[1].replace(/['"]+/g, '')
      return properties[attr];
    });

    return labelText;
  },

  _setLabelIcon: function (text, options) {
    console.log('Options', options);
     var icon = labelIcon({
      text: text,
      iconAnchor: options.offset || [0, 0],
      color: options.color, //color in rgb array
      font: options.font, // {decoration, family, size, style, weight}
      haloColor: options.haloColor // {size, color}
    });

    this.setIcon(icon);
  }
});

export function labelMarker (latlng, options) {
  return new LabelMarker(latlng, options);
}

export default labelMarker;

import L from 'leaflet';

export var LabelIcon = L.DivIcon.extend({
  options: {
    iconSize: null,
    className: 'esri-leaflet-webmap-labels',
    text: '',
    color: undefined, //color in rgb array
    font: undefined, // {decoration, family, size, style, weight}
    haloColor: undefined // {size, color}
  },

  createIcon: function (oldIcon) {
    var div = (oldIcon && oldIcon.tagName === 'DIV') ? oldIcon : document.createElement('div');
    var options = this.options;

    div.innerHTML = '<div style="position: relative; left: -50%; text-shadow: 1px 1px 0px '+this.options.haloColor+', -1px 1px 0px '+this.options.haloColor+', 1px -1px 0px '+this.options.haloColor+', -1px -1px 0px '+this.options.haloColor+';">' + options.text + '</div>';

    // label.css
    div.style.fontSize = this.options.font.size + 'pt' || '1em'; // '1em';
    div.style.fontWeight = this.options.font.weight || 'bold';
    div.style.fontFamily = this.options.font.family || 'Arial';
    div.style.fontStyle = this.options.font.style || 'normal';
    div.style.color = this.options.color || 'gray';
    
    //div.style.textTransform = 'uppercase';
    div.style.textAlign = 'center';
    div.style.whiteSpace = 'wrap';

    if (options.bgPos) {
      var bgPos = L.point(options.bgPos);
      div.style.backgroundPosition = (-bgPos.x) + 'px ' + (-bgPos.y) + 'px';
    }
    this._setIconStyles(div, 'icon');

    return div;
  }
});

export function labelIcon (options) {
  return new LabelIcon(options);
}

export default labelIcon;
